<template>
  <div>
    <div class="row form-group">
      <label
        class="col-xl-2 col-lg-2 col-sm-2 col-12 text-lg-right text-sm-left"
        >CEP*</label
      >
      <div class="col-xl-10 col-lg-10 col-sm-10 col-12">
        <the-mask
          aria-label="CEP"
          :masked="true"
          name="cep"
          class="form-control"
          v-model="cep"
          :mask="['#####-###']"
          required
        />
      </div>
    </div>

    <div class="row form-group">
      <label
        class="col-xl-2 col-lg-2 col-sm-2 col-12 text-lg-right text-sm-left"
        >Estado*</label
      >
      <div class="col-xl-10 col-lg-10 col-sm-10 col-12">
        <select
          class="form-control"
          @change="changeCity($event)"
          id="select-state"
          aria-label="Estado"
          name="estado"
          v-model="form.estado"
          required
        >
          <option disabled value="">Por favor, selecione um estado</option>
          <option
            v-for="(item, index) in states"
            :key="index"
            :value="item.sigla"
          >
            {{ item.nome }}
          </option>
        </select>
      </div>
    </div>

    <div class="row form-group">
      <label
        class="col-xl-2 col-lg-2 col-sm-2 col-12 text-lg-right text-sm-left"
        >Cidade*</label
      >
      <div class="col-xl-10 col-lg-10 col-sm-10 col-12">
        <select
          class="form-control"
          aria-label="Cidade"
          name="cidade"
          v-model="form.cidade"
          required
        >
          <option disabled value="">
            {{ placeholder_city }}
          </option>
          <option
            v-for="(item, index) in cities_state.cidades"
            :key="index"
            :value="item"
            required
          >
            {{ item }}
          </option>
        </select>
      </div>
    </div>

    <div class="row form-group">
      <label
        class="col-xl-2 col-lg-2 col-sm-2 col-12 text-lg-right text-sm-left"
        >Rua*</label
      >
      <div class="col-xl-10 col-lg-10 col-sm-10 col-12">
        <input
          class="form-control"
          aria-label="Rua"
          type="text"
          name="rua"
          id="rua"
          v-model="form.rua"
          required
        />
      </div>
    </div>

    <div class="row form-group">
      <label
        class="col-xl-2 col-lg-2 col-sm-2 col-12 text-lg-right text-sm-left"
        >Bairro</label
      >
      <div class="col-xl-10 col-lg-10 col-sm-10 col-12">
        <input
          class="form-control"
          aria-label="Bairro"
          type="bairro"
          name="bairro"
          id="bairro"
          v-model="form.bairro"
        />
      </div>
    </div>

    <div class="row form-group">
      <label
        class="col-xl-2 col-lg-2 col-sm-2 col-12 text-lg-right text-sm-left"
        >Número</label
      >
      <div class="col-xl-10 col-lg-10 col-sm-10 col-12">
        <input
          class="form-control"
          aria-label="Número"
          type="numero"
          name="numero"
          id="numero"
          v-model="form.numero"
        />
      </div>
    </div>

    <div class="row form-group">
      <label
        class="col-xl-2 col-lg-2 col-sm-2 col-12 text-lg-right text-sm-left"
        >Complemento</label
      >
      <div class="col-xl-10 col-lg-10 col-sm-10 col-12">
        <input
          class="form-control"
          aria-label="Complemento"
          type="complemento"
          name="complemento"
          id="complemento"
          v-model="form.complemento"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import cities_json from "./locations/city.json";

export default {
  props: ["endpoint", "login", "session", "edit", "pages"],
  data() {
    return {
      button: "Cadastrar-se",
      disabled: false,
      cep: "",
      change_edit: 0,
      form: {
        estado: "",
        cidade: "",
        cep: this.cep,
        rua: "",
        numero: "",
        bairro: "",
        complemento: "",
      },
      states: cities_json.estados,
      cities_state: "",
      placeholder_city: "Por favor, selecione um estado primeiro",
      cep_change: false,
    };
  },

  created() {
    if (this.edit) {
      let address = JSON.parse(this.edit);
      this.cep = address.cep;
      this.form.rua = address.rua;
      this.form.estado = address.estado;
      this.changeCityEdit(this.form.estado);
      this.form.cidade = address.cidade;
      this.form.numero = address.numero;
      this.change_edit = 1;
      this.form.bairro = address.bairro;
    }
  },

  watch: {
    cep() {
      setTimeout(() => {
        if (this.cep_change) {
          this.ViaCep();
        }
      }, 200);
    },
  },

  updated() {
    this.cep_change = true;
    console.log("aqui");
  },

  methods: {
    ViaCep() {
      if (this.cep) {
        if (this.cep.length == 9) {
          axios
            .get("https://viacep.com.br/ws/" + this.cep + "/json/")
            .then((response) => {
              if (response.data.erro) {
                this.$swal("Oops!", "CEP Inválido!", "error");
                this.cep = "";
              } else {
                this.form.estado = response.data.uf;
                this.form.cidade = response.data.localidade;
                this.form.rua = response.data.logradouro;
                this.form.bairro = response.data.bairro;
              }
            })
            .catch((error) => {})
            .finally(() => {
              this.changeCityEdit(this.form.estado);
            });
        }
      }
    },

    changeCityEdit(event) {
      this.placeholder_city = "Por favor, selecione uma cidade";
      this.cities_state = this.states.filter(function (state) {
        return state.sigla === event;
      });
      this.cities_state = this.cities_state[0];
    },

    changeCity(event) {
      this.placeholder_city = "Por favor, selecione uma cidade";
      this.cities_state = this.states.filter(function (state) {
        return state.sigla === event.target.value;
      });
      this.cities_state = this.cities_state[0];
    },
  },
};
</script>