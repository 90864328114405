<template>
  <div>
    <div class="login">
      <div class="container">
        <div class="row">
          <div
            class="col-12 align-items-center justify-content-center d-flex"
            style="flex-direction: column"
          >
            <div class="bloco-input-login">
              <the-mensagem
                v-if="message"
                :message="message"
                :type="type"
              ></the-mensagem>
            </div>
            <img :src="this.origin + '/img/front/logo.png'" alt="Logo Fabet" />
            <form
              @submit.prevent="validaForm()"
              method="POST"
              :action="endpoint"
              id="formRecuperar"
            >
              <h1>Recuperar senha?</h1>
              <div class="bloco-input-login" :class="erros.cpfCnpj">
                <label>CPF</label>
                <the-mask
                  :masked="true"
                  :mask="['###.###.###-##']"
                  type="text"
                  aria-label="CPF"
                  v-model="form.cpfCnpj"
                  name="cpfCnpj"
                  required
                  placeholder=" "
                />
              </div>
              <input type="hidden" name="_method" value="POST" />
              <input type="hidden" name="_token" :value="token" />
              <div class="erros" v-if="erroFrase.length">
                <ul>
                  <li v-for="erro in erroFrase" :key="erro">
                    <i class="fa fa-times" aria-hidden="true"></i> {{ erro }}
                  </li>
                </ul>
              </div>
              <button
                type="button"
                :disabled="button.disabled"
                v-html="button.text"
                @click="validaForm()"
                class="login-button"
              ></button>
              <a href="/cadastro">Não tem uma conta? Cadastre-se</a>
              <a href="/login" class="esqueci">Já tenho uma conta</a>
              <a href="https://drive.google.com/file/d/1kVKlMpWO8MRcTuk2RgpF10mOqiHEHU9g/view?usp=sharing" target="_blank" class="politica">Política de privacidade</a>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["endpoint", "token", "message", "type"],
  data() {
    return {
      origin: window.origin,
      form: {
        cpfCnpj: "",
      },
      erros: {
        cpfCnpj: "",
      },
      erroFrase: [],
      button: {
        disabled: false,
        text: "Enviar",
      },
    };
  },

  methods: {
    validaForm() {
      this.button = {
        disabled: true,
        text: '<i class="fa fa-spinner fa-pulse"></i>',
      };
      this.erroFrase = [];
      if (!this.form.cpfCnpj) {
        this.erros.cpfCnpj = "bloco-input-erro";
        this.erroFrase.push("Insira um CPF");
      } else if (this.form.cpfCnpj.length < 14) {
        this.erros.cpfCnpj = "bloco-input-erro";
        this.erroFrase.push("Insira um CPF válido");
      }

      if (this.form.cpfCnpj && this.form.cpfCnpj.length == 14) {
        document.getElementById("formRecuperar").submit();
      }
      this.button = {
        disabled: false,
        text: "Enviar",
      };
    },
  },
};
</script>
