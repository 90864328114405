<template>
  <div>
    <div class="login">
      <div class="container">
        <div class="row">
          <div
            class="col-12 align-items-center justify-content-center d-flex"
            style="flex-direction: column"
          >
            <div class="bloco-input-login">
              <the-mensagem
                v-if="message"
                :message="message"
                :type="typeMessage"
              ></the-mensagem>
            </div>

            <img src="img/front/logo.png" alt="Logo Fabet" />
            <form method="POST" :action="endpoint" id="formLogin">
              <div class="bloco-input-login" :class="erros.login">
                <label>CPF/CNPJ</label>
                <the-mask
                  aria-label="Cpf/CNPJ"
                  name="cpfCnpj"
                  :masked="true"
                  v-model="form.login"
                  :mask="['###.###.###-##', '##.###.###/####-##']"
                  required
                  placeholder=" "
                />
              </div>
              <div class="bloco-input-login" :class="erros.senha">
                <label>Senha</label>
                <i
                  class="fa fa-eye-slash"
                  aria-hidden="true"
                  @click="confirmaSenha()"
                  id="olho-fechado2"
                ></i>
                <i
                  class="fa fa-eye"
                  aria-hidden="true"
                  @click="confirmaSenha()"
                  id="olho2"
                ></i>
                <input
                  type="password"
                  aria-label="Senha"
                  v-model="form.senha"
                  name="password"
                  id="senha2"
                  required
                  placeholder=" "
                />
              </div>
              <div class="erros" v-if="erroFrase.length">
                <ul>
                  <li v-for="erro in erroFrase" :key="erro">
                    <i class="fa fa-times" aria-hidden="true"></i> {{ erro }}
                  </li>
                </ul>
              </div>
              <input type="hidden" name="_token" :value="token">
              <input type="hidden" name="_method" value="POST">
              <button
                :disabled="button.disabled"
                type="submit"
                v-html="button.text"
                @click="validaForm()"
                class="login-button"
              ></button>
              <a href="/cadastro">Não tem uma conta? Cadastre-se</a>
              <a href="/recuperar-senha" class="esqueci">Esqueci minha senha</a>
              <a href="https://drive.google.com/file/d/1kVKlMpWO8MRcTuk2RgpF10mOqiHEHU9g/view?usp=sharing" target="_blank" class="politica">Política de privacidade</a>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["endpoint", "message", "typeMessage", "token"],
  data() {
    return {
      form: {
        login: "",
        senha: "",
      },
      erros: {
        login: "",
        senha: "",
      },
      erroFrase: [],
      button: {
        disabled: false,
        text: "Entrar",
      },
    };
  },
  methods: {
    validaForm() {
      this.erroFrase = [];
      if (!this.form.login) {
        this.erros.login = "bloco-input-erro";
        this.erroFrase.push("Insira seu CPF ou CNPJ");
      }
      if (!this.form.senha) {
        this.erros.senha = "bloco-input-erro";
        this.erroFrase.push("Insira sua senha");
      }
      if (this.form.login && this.form.senha) {
        document.getElementById("formLogin").submit();
      }
    },
    confirmaSenha() {
      var senha = document.getElementById("senha2");
      var icon = document.getElementById("olho2");
      var icon2 = document.getElementById("olho-fechado2");
      if (senha.getAttribute("type") == "password") {
        senha.setAttribute("type", "text");
        icon.style.display = "flex";
        icon2.style.display = "none";
      } else {
        senha.setAttribute("type", "password");
        icon.style.display = "none";
        icon2.style.display = "flex";
      }
    },
  },
};
</script>
