<template>
  <div>
    <div class="cadastro_cv">
      <div class="container">
        <h1 class="ui-titulo">Edição de currículo:</h1>
        <div class="col-12 col-xl-12 mt-4">
          <div class="bloco-radio">
            <input id="disponivel" type="checkbox" v-model="form.disponivel" />
            <label for="disponivel">Disponível</label>
          </div>
        </div>
        <form>
          <div class="row">
            <div
              class="col-12 d-flex align-items-center subtitulo"
              style="flex-direction: row"
            >
              <div class="linha"></div>
              <p>Informações Pessoais</p>
              <div class="linha"></div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="bloco-input" :class="erros.nome">
                <label>Nome Completo*</label>
                <input
                  type="text"
                  name="nome"
                  v-model="form.nome"
                  required
                  aria-label="Nome"
                  placeholder=" "
                />
              </div>
            </div>
            <div class="col-12 col-xl-6" :class="erros.email">
              <div class="bloco-input">
                <label>E-mail*</label>
                <input
                  type="text"
                  name="email"
                  v-model="form.email"
                  required
                  aria-label="E-mail"
                  placeholder=" "
                />
              </div>
            </div>
            <div class="col-12 col-xl-6" :class="erros.cpf">
              <div class="bloco-input">
                <label>CPF*</label>
                <the-mask
                  aria-label="CPF"
                  :masked="true"
                  v-model="form.cpf"
                  :mask="['###.###.###-##']"
                  required
                  placeholder=" "
                />
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="bloco-input" :class="erros.escolaridade">
                <label>Escolaridade*</label>
                <img
                  :src="this.origin + '/img/front/icons/seta-preta.png'"
                  alt="Icon Seta"
                />
                <select
                  name="escolaridade"
                  v-model="form.escolaridade"
                  required
                  aria-label="escolaridade"
                  placeholder=" "
                >
                  <option value="0">Ensino fundamental incompleto</option>
                  <option value="1" selected>
                    Ensino fundamental completo
                  </option>
                  <option value="2">Ensino médio incompleto</option>
                  <option value="3">Ensino médio completo</option>
                  <option value="4">Ensino superior</option>
                </select>
              </div>
            </div>
            <div
              class="col-12 col-xl-4 justify-content-center d-flex"
              style="flex-direction: column"
            >
              <p>Gênero*</p>
              <div class="bloco-genero">
                <div
                  class="botao-genero"
                  :class="this.form.genero === 'M' ? 'botao-ativo' : ''"
                  v-on:click="form.genero = 'M'"
                >
                  <img
                    v-bind:src="
                      this.form.genero === 'M'
                        ? this.origin + '/img/front/icons/homem-preto.png'
                        : this.origin + '/img/front/icons/homem-cinza.png'
                    "
                    alt="Icon Homem"
                  />
                  <p>Masculino</p>
                </div>
                <div
                  class="botao-genero"
                  :class="this.form.genero === 'F' ? 'botao-ativo' : ''"
                  v-on:click="form.genero = 'F'"
                >
                  <img
                    v-bind:src="
                      this.form.genero === 'F'
                        ? this.origin + '/img/front/icons/mulher-preto.png'
                        : this.origin + '/img/front/icons/mulher-cinza.png'
                    "
                    alt="Icon mulher"
                  />
                  <p>Feminino</p>
                </div>
                <div
                  class="botao-genero"
                  :class="this.form.genero === 'O' ? 'botao-ativo' : ''"
                  v-on:click="form.genero = 'O'"
                >
                  <p>Outros</p>
                </div>
                <div
                  class="botao-genero"
                  :class="this.form.genero === 'NI' ? 'botao-ativo' : ''"
                  v-on:click="form.genero = 'NI'"
                >
                  <p>Não informar</p>
                </div>
              </div>
            </div>
            <div class="col-12 col-xl-4">
              <div class="bloco-input" :class="erros.telefone">
                <label>Telefone*</label>
                <the-mask
                  aria-label="Telefone"
                  :masked="true"
                  v-model="form.telefone"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  required
                  placeholder=" "
                />
              </div>
              <div class="bloco-input" :class="erros.nascimento">
                <label>Data de nascimento*</label>
                <the-mask
                  aria-label="Data de Nascimento"
                  :masked="true"
                  v-model="form.nascimento"
                  :mask="['##/##/####']"
                  required
                  placeholder=" "
                />
              </div>
            </div>
            <div class="col-12 col-xl-4">
              <div class="bloco-input" :class="erros.cnh">
                <label>CNH*</label>
                <img
                  :src="this.origin + '/img/front/icons/seta-preta.png'"
                  alt="Icon Seta"
                />
                <select
                  name="CNH"
                  v-model="form.cnh"
                  required
                  aria-label="CNH"
                  placeholder=" "
                >
                  <option value="Não possui" selected>Não tenho</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="AB">AB</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                  <option value="E">E</option>
                </select>
              </div>
              <div class="bloco-input" :class="erros.mudar">
                <label>Mudar-se para outra Cidade/Estado?*</label>
                <img
                  :src="this.origin + '/img/front/icons/seta-preta.png'"
                  alt="Icon Seta"
                />
                <select
                  name="mudar"
                  v-model="form.mudar"
                  required
                  aria-label="mudar"
                  placeholder=" "
                >
                  <option value="0" selected>Não</option>
                  <option value="1">Sim</option>
                </select>
              </div>
            </div>
            <div class="col-12">
              <div class="bloco-input">
                <label>Cargo pretendido / Objetivo Profissional*</label>
                <textarea
                  type="text"
                  name="objetivo"
                  v-model="form.objetivo"
                  required
                  aria-label="Objetivo"
                  placeholder=" "
                ></textarea>
              </div>
            </div>
            <div
              class="col-12 d-flex align-items-center subtitulo"
              style="flex-direction: row"
            >
              <div class="linha"></div>
              <p>Experiência Profissional</p>
              <div class="linha"></div>
            </div>
            <div
              class="col-12 align-items-center"
              v-for="(experiencia, index) in form.experiencias"
              :key="index"
            >
              <h4 class="titulo-blocos-add">Experiência:</h4>
              <div class="bloco-input">
                <label>Nome da empresa*</label>
                <input
                  type="text"
                  name="nome_empresa"
                  v-model="form.experiencias[index].nome_empresa"
                  required
                  aria-label="Nome da empresa"
                  placeholder=" "
                />
              </div>
              <div class="bloco-input">
                <label>Cargo*</label>
                <input
                  type="text"
                  name="cargo"
                  v-model="form.experiencias[index].cargo"
                  required
                  aria-label="Cargo"
                  placeholder=" "
                />
              </div>
              <div class="alinhar-datas">
                <div class="bloco-input">
                  <label>Data Início</label>
                  <the-mask
                    aria-label="Data Início"
                    :masked="true"
                    v-model="form.experiencias[index].data_inicio"
                    :mask="['##/##/####']"
                    required
                    placeholder=" "
                  />
                </div>
                <div style="width: 20px"></div>
                <div class="bloco-input">
                  <label>Data Final</label>
                  <the-mask
                    aria-label="Data Final"
                    :masked="true"
                    v-model="form.experiencias[index].data_fim"
                    :mask="['##/##/####']"
                    required
                    placeholder=" "
                  />
                </div>
              </div>
              <i
                class="fa fa-times-circle remove-registro"
                aria-hidden="true"
                @click="removeExperiences(index)"
              ></i>
            </div>
            <div
              class="col-12 align-items-center d-flex justify-content-center"
            >
              <p class="adicionar" @click="addExperiences">
                <img
                  :src="this.origin + '/img/front/icons/adicionar.png'"
                  alt="Icon Adicionar"
                />Adicionar registro
              </p>
            </div>
            <div
              class="col-12 d-flex align-items-center subtitulo"
              style="flex-direction: row"
            >
              <div class="linha"></div>
              <p>Cursos</p>
              <div class="linha"></div>
            </div>
            <div
              class="col-12 align-items-center"
              v-for="(formacoes, index) in form.formacoes"
              :key="index"
            >
              <h4 class="titulo-blocos-add">Cursos:</h4>
              <div class="">
                <div class="bloco-input">
                  <label>Curso*</label>
                  <input
                    type="text"
                    name="nome_curso"
                    v-model="form.formacoes[index].nome_curso"
                    required
                    placeholder=" "
                    aria-label="Nome do curso"
                  />
                </div>
                <div class="bloco-input">
                  <label>Instituição*</label>
                  <input
                    type="text"
                    name="instituicao"
                    v-model="form.formacoes[index].instituicao"
                    required
                    aria-label="Instituição"
                    placeholder=" "
                  />
                </div>
                <div class="alinhar-datas">
                  <div class="bloco-input">
                    <label>Data Início</label>
                    <the-mask
                      aria-label="Data Início"
                      :masked="true"
                      v-model="form.formacoes[index].data_inicio"
                      :mask="['##/##/####']"
                      required
                      placeholder=" "
                    />
                  </div>
                  <div style="width: 20px"></div>
                  <div class="bloco-input">
                    <label>Data Final</label>
                    <the-mask
                      aria-label="Data Final"
                      :masked="true"
                      v-model="form.formacoes[index].data_fim"
                      :mask="['##/##/####']"
                      required
                      placeholder=" "
                    />
                  </div>
                </div>
                <i
                  class="fa fa-times-circle remove-registro"
                  aria-hidden="true"
                  @click="removeGraduation(index)"
                ></i>
              </div>
              <!-- <div class="bloco-input">
                <label>Descrição*</label>
                <textarea
                  type="text"
                  name="descricao"
                  v-model="form.formacoes[index].descricao"
                  required
                  aria-label="Descrição"
                  placeholder=" "
                ></textarea>
              </div> -->
            </div>
            <div class="col-12 align-items-center d-flex justify-content-cente">
              <p class="adicionar" @click="addGraduation">
                <img
                  :src="this.origin + '/img/front/icons/adicionar.png'"
                  alt="Icon Adicionar"
                />Adicionar registro
              </p>
            </div>
            <div
              class="col-12 d-flex align-items-center subtitulo"
              style="flex-direction: row"
            >
              <div class="linha"></div>
              <p>Endereço</p>
              <div class="linha"></div>
            </div>
            <div class="col-12 col-xl-6" :class="erros.cep">
              <div class="bloco-input">
                <label>CEP*</label>
                <the-mask
                  aria-label="Cep"
                  :masked="true"
                  v-model="form.cep"
                  :mask="['#####-###']"
                  required
                  placeholder=" "
                />
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="bloco-input" :class="erros.rua">
                <label>Rua*</label>
                <input
                  type="text"
                  name="rua"
                  v-model="form.rua"
                  required
                  aria-label="Rua"
                  placeholder=" "
                />
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="bloco-input" :class="erros.bairro">
                <label>Bairro*</label>
                <input
                  type="text"
                  name="bairro"
                  v-model="form.bairro"
                  required
                  aria-label="Bairro"
                  placeholder=" "
                />
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="bloco-input">
                <label>Número</label>
                <input
                  type="text"
                  name="numero"
                  v-model="form.numero"
                  required
                  aria-label="Número"
                  placeholder=" "
                />
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="bloco-input" :class="erros.estado">
                <label>Estado*</label>
                <select
                  required
                  v-model="model_state"
                  @change="selecionaCidade(model_state)"
                >
                  <option
                    v-for="(option, index) in select_state"
                    :key="index"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="bloco-input" :class="erros.cidade">
                <label>Cidade*</label>
                <select required v-model="form.cidade">
                  <option value="" selected>Selecione um estado</option>
                  <option
                    v-for="(option, index) in select_cities"
                    :key="index"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-xl-12">
              <div class="bloco-input">
                <label>Complemento</label>
                <textarea
                  type="text"
                  name="complemento"
                  v-model="form.complemento"
                  required
                  aria-label="Complemento"
                  placeholder=" "
                ></textarea>
              </div>
            </div>
          </div>
        </form>
        <div
          class="col-12 d-flex align-items-center subtitulo"
          style="flex-direction: row"
        >
          <div class="linha"></div>
          <p>Arquivo</p>
          <div class="linha"></div>
        </div>
        <div
          class="col-12 align-items-center d-flex justify-content-center bloco-file"
          style="flex-direction: column"
        >
          <a
            v-if="image != ''"
            alt="Anexo"
            class="ui-button botao-curriculo"
            :href="image"
            target="_blank"
            >Ver arquivo do curriculo</a
          >
          <p :class="erros.foto">Selecione um arquivo. Tamanho máximo 20MB</p>
          <input
            class="file"
            type="file"
            name="imagem"
            v-on:change="onImageChange"
          />
        </div>
        <div class="col-12">
          <div class="erros" v-if="erroFrase.length">
            <ul>
              <li v-for="erro in erroFrase" :key="erro">
                <i class="fa fa-times" aria-hidden="true"></i> {{ erro }}
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-5">
          <the-mensagem
            :message="mensagem"
            :type="'sucesso'"
            v-if="mensagem"
          ></the-mensagem>
        </div>
        <div
          class="col-12 align-items-center d-flex justify-content-center"
          style="flex-direction: column"
        >
          <button
            :disabled="button.disabled"
            v-html="button.text"
            @click="validaForm()"
          >
            {{ button.text }}
          </button>
        </div>
        <div
          class="container align-items-center d-flex justify-content-center"
          style="flex-direction: column"
        >
          <div class="criado-sucesso">
            <p>Seu currículo foi criado com sucesso!</p>
          </div>
          <div class="criado-falha">
            <p>Ocorreu um erro ao criar seu currículo</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import brazil from "/public/cities/cidades.json";
import axios from "axios";
export default {
  props: ["usuario", "curriculum"],
  data() {
    return {
      origin: window.origin,
      erroFrase: [],
      select_cities: [],
      select_state: [],
      model_state: "",
      model_city: "",
      mensagem: "",
      image: this.curriculum.foto,
      form: {
        nome: this.curriculum.nome,
        email: this.curriculum.email,
        cpf: this.curriculum.cpf,
        rg: "",
        escolaridade: this.curriculum.escolaridade,
        genero: this.curriculum.genero,
        telefone: this.curriculum.telefone,
        nascimento: this.curriculum.nascimento,
        cnh: this.curriculum.cnh,
        mudar: this.curriculum.mudar,
        objetivo: this.curriculum.objetivo,
        cep: this.curriculum.cep,
        rua: this.curriculum.rua,
        bairro: this.curriculum.bairro,
        cidade: this.curriculum.cidade,
        estado: this.curriculum.estado,
        numero: this.curriculum.numero,
        complemento:
          this.curriculum.complemento != null
            ? ""
            : this.curriculum.complemento,
        disponivel: this.curriculum.disponivel,
        foto: "",
        experiencias: this.curriculum.experiences,
        formacoes: this.curriculum.graduations,
      },
      erros: {
        nome: "",
        email: "",
        cpf: "",
        rg: "",
        telefone: "",
        nascimento: "",
        cnh: "",
        rua: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        foto: "",
        objetivo: "",
        mudar: "",
        escolaridade: "",
      },
      button: {
        disabled: false,
        text: "Salvar",
      },
    };
  },
  created() {
    this.model_state = this.curriculum.estado;
    this.model_city = this.curriculum.cidade;

    for (var city of brazil.estados) {
      this.select_state.push({
        value: city.sigla,
        label: city.nome,
      });
    }
  },
  watch: {
    "form.cep"() {
      this.ViaCep();
    },
    "form.estado"() {
      this.select_cities = brazil.estados.filter((estado) => {
        if (estado.sigla == this.model_state) {
          return estado.cidades;
        }
      });
      if (this.select_cities[0]) {
        for (var city of this.select_cities[0].cidades) {
          this.select_cities.push({
            value: city,
            label: city,
          });
        }
      }
    },
    model_state() {
      this.select_cities = brazil.estados.filter((estado) => {
        if (estado.sigla == this.model_state) {
          return estado.cidades;
        }
      });
      if (this.select_cities[0]) {
        for (var city of this.select_cities[0].cidades) {
          this.select_cities.push({
            value: city,
            label: city,
          });
        }
      }
    },
  },
  methods: {
    selecionaCidade(model) {
      this.select_cities = brazil.estados.filter((estado) => {
        if (estado.sigla == model) {
          return estado.cidades;
        }
      });
      if (this.select_cities[0]) {
        for (var city of this.select_cities[0].cidades) {
          this.select_cities.push({
            value: city,
            label: city,
          });
        }
      }
    },
    ViaCep: function () {
      if (this.form.cep) {
        if (this.form.cep.length == 9) {
          axios.get("viacep/" + this.form.cep).then((response) => {
            if (response.data.erro) {
              this.$swal("Oops!", "CEP Inválido!", "error");
              this.cep = "";
            } else {
              this.model_state = response.data.uf;
              this.model_city = response.data.localidade;
              this.form.estado = response.data.uf;
              this.form.cidade = response.data.localidade;
              this.form.rua = response.data.logradouro;
              this.form.bairro = response.data.bairro;
            }
            setTimeout(() => {
              this.$refs.cep.$el.focus();
            }, 200);
          });
        }
      }
    },
    addExperiences() {
      this.form.experiencias.push({
        nome_empresa: "",
        cargo: "",
        data_inicio: "",
        data_fim: "",
      });
    },
    removeExperiences(index) {
      this.form.experiencias.splice(index, 1);
    },
    addGraduation() {
      this.form.formacoes.push({
        nome_curso: "",
        instituicao: "",
        data_inicio: "",
        data_fim: "",
        descricao: "",
      });
    },
    removeGraduation(index) {
      this.form.formacoes.splice(index, 1);
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validaForm() {
      this.erroFrase = [];
      this.mensagem = "";
      for (const key in this.erros) {
        if (Object.hasOwnProperty.call(this.erros, key)) {
          this.erros[key] = "";
        }
      }
      if (!this.form.nome) {
        this.erros.nome = "bloco-input-erro";
        this.erroFrase.push("Nome é um campo obrigatório");
      }
      if (!this.form.email) {
        this.erros.email = "bloco-input-erro";
      } else if (!this.validEmail(this.form.email)) {
        this.erros.email = "bloco-input-erro";
        this.erroFrase.push("Insira um e-mail válido");
      }
      if (!this.form.cpf) {
        this.erros.cpf = "bloco-input-erro";
        this.erroFrase.push("CPF é um campo obrigatório");
      } else if (this.form.cpf.length != 14) {
        this.erros.email = "bloco-input-erro";
        this.erroFrase.push("Insira um CPF válido");
      }
      if (!this.form.telefone) {
        this.erros.telefone = "bloco-input-erro";
        this.erroFrase.push("Telefone é um campo obrigatório");
      }
      if (!this.form.nascimento) {
        this.erros.nascimento = "bloco-input-erro";
        this.erroFrase.push("Data de nascimento é um campo obrigatório");
      }
      if (!this.form.cnh) {
        this.erros.cnh = "bloco-input-erro";
        this.erroFrase.push("CNH é um campo obrigatório");
      }
      if (!this.form.cidade) {
        this.erros.cidade = "bloco-input-erro";
        this.erroFrase.push("Cidade é um campo obrigatório");
      }
      if (!this.form.rua) {
        this.erros.rua = "bloco-input-erro";
        this.erroFrase.push("Rua é um campo obrigatório");
      }
      if (!this.form.bairro) {
        this.erros.bairro = "bloco-input-erro";
        this.erroFrase.push("Bairro é um campo obrigatório");
      }
      if (!this.form.estado) {
        this.erros.estado = "bloco-input-erro";
        this.erroFrase.push("Estado é um campo obrigatório");
      }
      if (!this.form.cep) {
        this.erros.cep = "bloco-input-erro";
        this.erroFrase.push("Cidade é um campo obrigatório");
      }
      if (!this.form.objetivo) {
        this.erros.objetivo = "bloco-input-erro";
        this.erroFrase.push("Objetivo é um campo obrigatório");
      }
      if (!this.form.escolaridade) {
        this.erros.escolaridade = "bloco-input-erro";
        this.erroFrase.push("Escolaridade é um campo obrigatório");
      }
      if (
        !this.erros.nome &&
        this.validEmail(this.form.email) &&
        !this.erros.telefone &&
        !this.erros.cpf &&
        !this.erros.genero &&
        !this.erros.nascimento &&
        !this.erros.cnh &&
        !this.erros.cidade &&
        !this.erros.estado &&
        !this.erros.rua &&
        !this.erros.bairro &&
        !this.erros.objetivo &&
        !this.erros.escolaridade
      ) {
        this.submitForm();
      }
    },
    async submitForm() {
      this.button.disabled = true;
      this.button.text = '<i class="fa fa-spinner fa-pulse"></i>';
      let formData = new FormData();
      for (const key in this.form) {
        if (key === "experiencias" || key === "formacoes") {
          formData.append(key, JSON.stringify(this.form[key]));
        } else {
          formData.append(key, this.form[key]);
        }
      }
      await axios
        .post("/atualiza-curriculo", formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          this.mensagem = response.data.message;
        })
        .catch((error) => {
          if (error?.response?.data?.errors) {
            this.presentErrorsFromBack(error.response.data.errors);
          }
        })
        .finally(() => {
          this.button.disabled = false;
          this.button.text = "Salvar";
        });
    },
    presentErrorsFromBack(errors) {
      for (const key in errors) {
        this.erros[key] = "bloco-input-erro";
        this.erroFrase.push(errors[key][0]);
      }
    },
    onImageChange(e) {
      this.form.foto = e.target.files[0];
    },
  },
};
</script>
