<template>
  <div>
    <div class="cadastro login">
      <div class="container">
        <form id="formCadastro" method="POST" :action="endpoint">
          <div class="row">
            <div class="col-12" style="flex-direction: column">
              <div class="cadastro-top">
                <div class="cadastro_titulo">
                  <h1 class="ui-titulo">Cadastro</h1>
                  <img
                    :src="this.origin + '/img/front/logo.png'"
                    alt="Logo Fabet"
                  />
                  <div class="cadastro_titulo-linha"></div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="bloco-input-cadastro" :class="erros.nome">
                <label>Nome Completo*</label>
                <input
                  type="text"
                  name="nome"
                  v-model="form.nome"
                  required
                  aria-label="Nome"
                  placeholder=" "
                  :disabled="allDisabled"
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="bloco-input-cadastro" :class="erros.email">
                <label>Email*</label>
                <input
                  type="email"
                  aria-label="Email"
                  name="email"
                  v-model="form.email"
                  required
                  placeholder=" "
                  :disabled="allDisabled"
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="bloco-input-cadastro" :class="erros.cpf">
                <label>CPF*</label>
                <the-mask
                  aria-label="CPF"
                  :masked="true"
                  v-model="form.cpf"
                  :mask="['###.###.###-##']"
                  required
                  name="cpfCnpj"
                  placeholder=" "
                  :disabled="allDisabled"
                />
                <i
                  class="fa fa-spinner fa-pulse"
                  aria-hidden="true"
                  v-if="this.validaCpf"
                ></i>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="bloco-input-cadastro" :class="erros.telefone">
                <label>Telefone*</label>
                <the-mask
                  aria-label="Telefone"
                  :masked="true"
                  v-model="form.telefone"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  required
                  placeholder=" "
                  :disabled="allDisabled"
                  name="telefone"
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="bloco-input-cadastro" :class="erros.senha">
                <i
                  class="fa fa-eye-slash"
                  aria-hidden="true"
                  @click="mostraSenha()"
                  id="olho-fechado"
                ></i>
                <i
                  class="fa fa-eye"
                  aria-hidden="true"
                  @click="mostraSenha()"
                  id="olho"
                ></i>
                <label>Senha*</label>
                <input
                  id="senha"
                  type="password"
                  aria-label="Senha"
                  v-model="form.senha"
                  required
                  placeholder=" "
                  :disabled="allDisabled"
                  name="password"
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="bloco-input-cadastro" :class="erros.confirmaSenha">
                <i
                  class="fa fa-eye-slash"
                  aria-hidden="true"
                  @click="confirmaSenha()"
                  id="olho-fechado2"
                ></i>
                <i
                  class="fa fa-eye"
                  aria-hidden="true"
                  @click="confirmaSenha()"
                  id="olho2"
                ></i>
                <label>Confirmar Senha*</label>
                <input
                  id="senha2"
                  type="password"
                  aria-label="Senha"
                  v-model="form.confirmaSenha"
                  required
                  placeholder=" "
                  :disabled="allDisabled"
                  name="confirmacao_password"
                />
              </div>
            </div>
            <div class="col-12 col-xl-6" :class="erros.cep">
              <div class="bloco-input">
                <label>CEP*</label>
                <the-mask
                  aria-label="Cep"
                  :masked="true"
                  v-model="form.cep"
                  :mask="['#####-###']"
                  required
                  placeholder=" "
                  :disabled="allDisabled"
                  name="cep"
                />
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="bloco-input" :class="erros.estado">
                <label>Estado*</label>
                <img
                  :src="this.origin + '/img/front/icons/seta-preta.png'"
                  alt="Icon Seta"
                />
                <select
                  required
                  @change="changeCity($event)"
                  v-model="form.estado"
                  :disabled="allDisabled"
                  name="estado"
                >
                  <option
                    v-for="(option, index) in states"
                    :key="index"
                    :value="option.sigla"
                  >
                    {{ option.nome }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="bloco-input" :class="erros.cidade">
                <img
                  :src="this.origin + '/img/front/icons/seta-preta.png'"
                  alt="Icon Seta"
                />
                <label>Cidade*</label>
                <select
                  required
                  :disabled="allDisabled"
                  v-model="form.cidade"
                  name="cidade"
                >
                  <option disabled selected>{{ placeholder_city }}</option>
                  <option
                    v-for="(option, index) in cities_state.cidades"
                    :key="index"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="bloco-input" :class="erros.bairro">
                <label>Bairro*</label>
                <input
                  type="text"
                  name="bairro"
                  v-model="form.bairro"
                  required
                  aria-label="Bairro"
                  placeholder=" "
                  :disabled="allDisabled"
                />
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="bloco-input" :class="erros.rua">
                <label>Rua*</label>
                <input
                  type="text"
                  name="rua"
                  v-model="form.rua"
                  required
                  aria-label="Rua"
                  placeholder=" "
                  :disabled="allDisabled"
                />
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="bloco-input">
                <label>Número</label>
                <input
                  type="text"
                  name="numero"
                  v-model="form.numero"
                  aria-label="Número"
                  placeholder=" "
                  :disabled="allDisabled"
                />
              </div>
            </div>
            <div class="col-12 col-xl-12">
              <div class="bloco-input">
                <label>Complemento</label>
                <textarea
                  type="text"
                  name="complemento"
                  v-model="form.complemento"
                  aria-label="Complemento"
                  placeholder=" "
                  :disabled="allDisabled"
                ></textarea>
              </div>
            </div>
            <div class="erros col-12" v-if="erroFrase.length">
              <ul>
                <li v-for="erro in erroFrase" :key="erro">
                  <i class="fa fa-times" aria-hidden="true"></i> {{ erro }}
                </li>
              </ul>
            </div>
            <div
              class="col-12 d-flex align-items-center justify-content-center"
              style="flex-direction: column"
            >
              <input type="hidden" name="_token" :value="token" />
              <input type="hidden" name="_method" value="POST" />
              <button
                type="button"
                :disabled="button.disabled"
                v-html="button.text"
                @click="validaForm()"
                class="login-button"
              ></button>
              <a href="/login">Já tenho uma conta</a>
              <a href="/recuperar-senha" class="esqueci">Esqueci minha senha</a>
              <a
                href="https://drive.google.com/file/d/1kVKlMpWO8MRcTuk2RgpF10mOqiHEHU9g/view?usp=sharing"
                target="_blank"
                class="politica"
                >Política de privacidade</a
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import cities_json from "./locations/city.json";
import axios from "axios";
export default {
  props: ["endpoint", "errors", "token"],
  data() {
    return {
      origin: window.origin,
      cities_state: "",
      states: cities_json.estados,
      placeholder_city: "Selecione um estado",
      form: {
        nome: "",
        email: "",
        cpf: "",
        telefone: "",
        senha: "",
        confirmaSenha: "",
        cep: "",
        rua: "",
        bairro: "",
        numero: "",
        estado: "",
        cidade: "",
        complemento: "",
      },
      erros: {
        nome: "",
        email: "",
        cpf: "",
        telefone: "",
        senha: "",
        confirmaSenha: "",
        cep: "",
        rua: "",
        bairro: "",
        numero: "",
        estado: "",
        cidade: "",
        complemento: "",
      },
      erroFrase: [],
      button: {
        disabled: false,
        text: "Cadastrar",
      },
      allDisabled: false,
      blockCpf: false,
      validaCpf: false,
    };
  },
  watch: {
    "form.cep"() {
      this.ViaCep();
    },
    "form.cpf"() {
      if (this.form.cpf.length == 14) {
        this.checkCpf();
      }
    },
    model_state() {
      this.select_cities = brazil.estados.filter((estado) => {
        if (estado.sigla == this.model_state) {
          return estado.cidades;
        }
      });
      if (this.select_cities[0]) {
        for (var city of this.select_cities[0].cidades) {
          this.select_cities.push({
            value: city,
            label: city,
          });
        }
      }
    },
  },
  created() {
    var errors = JSON.parse(this.errors);
    errors.forEach((e) => {
      this.erroFrase.push(e);
    });
  },
  methods: {
    ViaCep: function () {
      if (this.form.cep) {
        if (this.form.cep.length == 9) {
          axios
            .get("viacep/" + this.form.cep)
            .then((response) => {
              if (response.data.erro) {
                this.$swal("Oops!", "CEP Inválido!", "error");
              } else {
                this.form.estado = response.data.uf;
                this.form.cidade = response.data.localidade;
                this.form.rua = response.data.logradouro;
                this.form.bairro = response.data.bairro;
              }
            })
            .finally(() => {
              this.changeCityEdit(this.form.estado);
            });
        }
      }
    },
    checkCpf() {
      this.allDisabled = true;
      this.button = {
        disabled: true,
        text: '<i class="fa fa-spinner fa-pulse"></i>',
      };
      (this.erroFrase = []), (this.validaCpf = true);
      axios
        .post("/api/consulta-cpf", { cpf: this.form.cpf })
        .then((res) => {
          if (res.data.length == 0) {
            this.erros.cpf = "bloco-input-erro";
            this.erroFrase.push("Este CPF não consta na nossa Base de Dados");
            this.blockCpf = true;
          } else {
            this.blockCpf = false;
          }
        })
        .finally(() => {
          this.validaCpf = false;
          this.allDisabled = false;
          this.button = {
            disabled: false,
            text: "Cadastrar",
          };
        });
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validaForm() {
      this.erros = [];
      this.erroFrase = [];
      this.button = {
        disabled: true,
        text: "Cadastrando...",
      };
      if (!this.form.nome) {
        this.erros.nome = "bloco-input-erro";
      }
      if (!this.form.email) {
        this.erros.email = "bloco-input-erro";
      } else if (!this.validEmail(this.form.email)) {
        this.erros.email = "bloco-input-erro";
        this.erroFrase.push("Insira um e-mail válido");
      }
      if (!this.form.cpf) {
        this.erros.cpf = "bloco-input-erro";
      } else if (this.form.cpf.length != 14) {
        this.erros.email = "bloco-input-erro";
        this.erroFrase.push("Insira um CPF válido");
      } else if (this.blockCpf) {
        this.erros.email = "bloco-input-erro";
        this.erroFrase.push("Este CPF não consta na nossa Base de Dados");
      }
      if (!this.form.telefone) {
        this.erros.telefone = "bloco-input-erro";
      } else if (this.form.telefone.length < 14) {
        this.erros.telefone = "bloco-input-erro";
        this.erroFrase.push("Insira um número de telefone válido");
      }
      if (!this.form.cidade) {
        this.erros.cidade = "bloco-input-erro";
      }
      if (!this.form.rua) {
        this.erros.rua = "bloco-input-erro";
      }
      if (!this.form.bairro) {
        this.erros.bairro = "bloco-input-erro";
      }
      if (!this.form.estado) {
        this.erros.estado = "bloco-input-erro";
      }
      if (!this.form.cep) {
        this.erros.cep = "bloco-input-erro";
      } else if (this.form.cep.length < 9) {
        this.erros.cep = "bloco-input-erro";
      }
      if (!this.form.senha) {
        this.erros.senha = "bloco-input-erro";
        this.erroFrase.push("Insira sua senha");
      } else if (this.form.senha.length < 8) {
        this.erros.senha = "bloco-input-erro";
        this.erroFrase.push("Sua senha precisa conter no mínimo 8 caracteres");
      }
      if (!this.form.confirmaSenha) {
        this.erros.confirmaSenha = "bloco-input-erro";
        this.erroFrase.push("Confirme a sua senha");
      } else if (this.form.confirmaSenha != this.form.senha) {
        this.erros.confirmaSenha = "bloco-input-erro";
        this.erroFrase.push("As senhas precisam ser iguais");
      }
      if (
        this.form.nome &&
        this.validEmail(this.form.email) &&
        this.form.telefone &&
        this.form.telefone.length >= 14 &&
        this.form.cpf &&
        !this.blockCpf &&
        this.form.cidade &&
        this.form.estado &&
        this.form.rua &&
        this.form.bairro &&
        this.form.cep.length > 8 &&
        this.form.senha &&
        this.form.senha.length >= 8 &&
        this.form.confirmaSenha &&
        this.form.confirmaSenha == this.form.senha
      ) {
        setTimeout(() => {
          document.getElementById("formCadastro").submit();
          this.button = {
            disabled: false,
            text: "Cadastrar",
          };
        }, 200);
      } else {
        this.button = {
          disabled: false,
          text: "Cadastrar",
        };
      }
    },
    mostraSenha() {
      var senha = document.getElementById("senha");
      var icon = document.getElementById("olho");
      var icon2 = document.getElementById("olho-fechado");
      if (senha.getAttribute("type") == "password") {
        senha.setAttribute("type", "text");
        icon.style.display = "flex";
        icon2.style.display = "none";
      } else {
        senha.setAttribute("type", "password");
        icon.style.display = "none";
        icon2.style.display = "flex";
      }
    },
    confirmaSenha() {
      var senha = document.getElementById("senha2");
      var icon = document.getElementById("olho2");
      var icon2 = document.getElementById("olho-fechado2");
      if (senha.getAttribute("type") == "password") {
        senha.setAttribute("type", "text");
        icon.style.display = "flex";
        icon2.style.display = "none";
      } else {
        senha.setAttribute("type", "password");
        icon.style.display = "none";
        icon2.style.display = "flex";
      }
    },
    changeCity(event) {
      this.placeholder_city = "Por favor, selecione uma cidade";
      this.cities_state = this.states.filter(function (state) {
        return state.sigla === event.target.value;
      });
      this.cities_state = this.cities_state[0];
    },
    changeCityEdit(event) {
      this.placeholder_city = "Por favor, selecione uma cidade";
      this.cities_state = this.states.filter(function (state) {
        return state.sigla === event;
      });
      this.cities_state = this.cities_state[0];
    },
  },
};
</script>
