<template>
  <div :class="'alert al ert-dismissible mensagem-' + type" class="alerta">
    <div type="button" class="close" data-dismiss="alert" aria-hidden="true">
      ×
    </div>
    <div class="paragrafo">
      {{ message }}
    </div>
  </div>
</template>
<script>
export default {
  props: ["message", "type"],
};
</script>