<template>
  <div>
    <div class="curriculos">
      <div class="container-flex">
        <div class="curriculos-top">
          <div class="curriculos_titulo">
            <h1 class="ui-titulo">Currículos</h1>
            <div class="curriculos_titulo-linha"></div>
          </div>
          <div class="curriculos_filtro" v-on:click="filtro = true">
            <img
              :src="this.origin + '/img/front/icons/filtro.png'"
              alt="Filtro"
            />
            <div class="curriculos_filtro-hover">Filtros</div>
          </div>
        </div>
        <div class="ui-pesquisa">
          <button>
            <img
              :src="this.origin + '/img/front/icons/search.png'"
              alt="Pesquisar"
              type="submit"
              @click="filtrar()"
            />
          </button>
          <input
            placeholder="Pesquisar..."
            type="text"
            name="pesquisa"
            v-model="pesquisa"
            @keyup.enter="filtrar()"
          />
        </div>
        <table class="tabela" v-if="this.curriculoCopia.length != 0">
          <thead>
            <th>#</th>
            <th>Nome</th>
            <th>Situação</th>
            <th>Cargo pretendido / Objetivo profissional</th>
            <th>Mais informações</th>
          </thead>
          <tbody>
            <tr v-for="(curriculo, index) in this.curriculoCopia" :key="index">
              <td>{{ curriculo.id }}</td>
              <td class="nome">{{ curriculo.nome }}</td>
              <td class="situacao">
                <div
                  class="curriculos_status disponivel"
                  v-if="curriculo.disponivel === 1"
                >
                  Disponível
                </div>
                <div class="curriculos_status contratado" v-else>
                  Contratado
                </div>
              </td>
              <td class="formacao">{{ curriculo.objetivo }}</td>
              <td class="informacoes">
                <a :href="'/curriculos/' + curriculo.slug"
                  >Ver mais informações</a
                >
              </td>
            </tr>
          </tbody>
          <tfoot>
            <!-- paginação aqui -->
          </tfoot>
        </table>
        <div
          class="mensagem-nao-encontrado"
          v-if="this.curriculoCopia.length === 0"
        >
          Nenhum currículo cadastrado
        </div>
        <div
          class="mensagem-nao-encontrado"
          v-if="this.mensagemErro != '' && this.curriculoCopia.length != 0"
        >
          {{ this.mensagemErro }}
        </div>
      </div>
    </div>
    <div class="curriculos-responsivo">
      <div class="container">
        <div class="curriculos-top">
          <div class="curriculos_titulo">
            <h1 class="ui-titulo">Currículos</h1>
          </div>
          <div class="curriculos_filtro" v-on:click="filtro = true">
            <img
              alt="Filtro"
              :src="this.origin + '/img/front/icons/filtro.png'"
            />
            <div class="curriculos_filtro-hover">Filtros</div>
          </div>
        </div>
        <div class="ui-pesquisa">
          <button>
            <img
              :src="this.origin + '/img/front/icons/search.png'"
              alt="Pesquisar"
              type="submit"
              @click="filtrar()"
            />
          </button>
          <input
            placeholder="Pesquisar..."
            type="text"
            name="pesquisa"
            v-model="pesquisa"
            @keyup.enter="filtrar()"
          />
        </div>
        <div class="blocos-responsivos">
          <div class="row">
            <div
              class="col-12"
              v-for="(curriculo, index) in this.curriculoCopia"
              :key="index"
              style="margin-bottom: 20px"
            >
              <div class="bloco-responsivo">
                <p class="responsivo-bold">{{ curriculo.nome }}</p>
                <p>
                  Cargo pretendido / Objetivo profissional:{{
                    curriculo.objetivo
                  }}
                </p>
                <div class="disponivel-bloco" v-if="curriculo.disponivel === 1">
                  Disponível
                </div>
                <div
                  class="indisponivel-bloco"
                  v-if="curriculo.disponivel === 0"
                >
                  Indisponível
                </div>
                <a :href="'/curriculos/' + curriculo.slug"
                  >Ver mais informações</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="filtros" v-if="filtro">
      <div class="filtros-top">
        <h3>Filtros:</h3>
        <img
          :src="this.origin + '/img/front/icons/close-azul.png'"
          alt="Fechar"
          v-on:click="filtro = false"
        />
      </div>
      <div class="filtros-botoes">
        <div
          class="filtros-botao"
          :class="this.genero === 'M' ? 'botao-ativo' : ''"
          v-on:click="genero = 'M'"
          @click="filtrar()"
        >
          <p>Masculino</p>
        </div>
        <div
          class="filtros-botao"
          :class="this.genero === 'F' ? 'botao-ativo' : ''"
          v-on:click="genero = 'F'"
          @click="filtrar()"
        >
          <p>Feminino</p>
        </div>
        <div
          class="filtros-botao"
          :class="this.genero === 'A' ? 'botao-ativo' : ''"
          v-on:click="genero = 'A'"
          @click="filtrar()"
        >
          <p>Ambos</p>
        </div>
      </div>
      <div class="filtros-idade">
        <div class="alinhar">
          <p>Idade máxima:</p>
          <p id="display">70</p>
        </div>
        <input
          type="range"
          name="idade"
          min="16"
          max="70"
          step="1"
          oninput="display.innerHTML=value"
          onchange="display.innerHTML=value"
          v-model="idade"
          @change="filtrar()"
        />
      </div>
      <div class="filtros-select">
        <p>CNH:</p>
        <img :src="this.origin + '/img/front/icons/seta.png'" alt="seta" />
        <select name="cnh" v-model="cnh" @change="filtrar()">
          <option value="Todos">Todos</option>
          <option value="A">A</option>
          <option value="B">B</option>
          <option value="AB">AB</option>
          <option value="C">C</option>
          <option value="D">D</option>
          <option value="E">E</option>
        </select>
      </div>
      <div class="filtros-select">
        <p>Estado / Região:</p>
        <img :src="this.origin + '/img/front/icons/seta.png'" alt="seta" />
        <select
          required
          @change="changeCity($event)"
          v-model="estado"
          name="estado"
        >
          <option value="Todos">Todos</option>
          <option class="titulo-select" disabled="disabled">Regiões:</option>
          <option value="Norte">Norte</option>
          <option value="Nordeste">Nordeste</option>
          <option value="Centro">Centro-Oeste</option>
          <option value="Sudeste">Sudeste</option>
          <option value="Sul">Sul</option>
          <option class="titulo-select" disabled="disabled">Estados:</option>
          <option
            v-for="(option, index) in states"
            :key="index"
            :value="option.sigla"
          >
            {{ option.nome }}
          </option>
        </select>
      </div>
      <div class="filtros-select">
        <p>Cidade:</p>
        <img :src="this.origin + '/img/front/icons/seta.png'" alt="seta" />
        <select required v-model="cidade" name="cidade" @change="filtrar()">
          <option value="Todos">Todas</option>
          <option disabled="disabled">Selecione um estado</option>
          <option
            v-for="(option, index) in cities_state.cidades"
            :key="index"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
      <div class="filtros-select">
        <p>Disposição mudar de cidade / estado?</p>
        <img class="seta-disposicao" :src="this.origin + '/img/front/icons/seta.png'" alt="seta" />
        <select required v-model="mudar" name="mudar" @change="filtrar()">
          <option value="Todos">Todos</option>
          <option value="0">Não</option>
          <option value="1">Sim</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import cities_json from "./locations/city.json";

export default {
  props: ["curriculos", "formacoes"],
  data() {
    return {
      origin: window.origin,
      cities_state: "",
      states: cities_json.estados,
      genero: "A",
      filtro: false,
      curriculoCopia: "",
      pesquisa: "",
      idade: 70,
      cnh: "Todos",
      estado: "Todos",
      cidade: "Todos",
      mudar: "Todos",
      mensagemErro: "",
    };
  },
  created() {
    this.curriculoCopia = JSON.parse(JSON.stringify(this.curriculos));
  },
  watch: {
    model_state() {
      this.select_cities = brazil.estados.filter((estado) => {
        if (estado.sigla == this.model_state) {
          return estado.cidades;
        }
      });
      if (this.select_cities[0]) {
        for (var city of this.select_cities[0].cidades) {
          this.select_cities.push({
            value: city,
            label: city,
          });
        }
      }
    },
  },
  methods: {
    filtrar() {
      axios
        .get(
          `/filtro?idade=${this.idade}&genero=${this.genero}&cnh=${this.cnh}&pesquisa=${this.pesquisa}&cidade=${this.cidade}&estado=${this.estado}&mudar=${this.mudar}`
        )
        .then((response) => {
          if (response.data.message) {
            this.mensagemErro = response.data.message;
            this.curriculoCopia = [];
          } else {
            this.mensagemErro = "";
            this.curriculoCopia = response.data;
          }
        })
        .catch((e) => {
          var msg = "Houve um erro ao filtrar! Por favor, tente novamente.";
          this.$swal("Oops!", msg, "error");
          console.log(e);
        })
        .finally(() => {});
    },
    changeCity(event) {
      if(this.estado != 'Norte' && this.estado != 'Nordeste' && this.estado != 'Centro' && this.estado != 'Sudeste' && this.estado != 'Sul' && this.estado != 'Todos'){
        console.log(this.estado);
        this.placeholder_city = "Por favor, selecione uma cidade";
        this.cities_state = this.states.filter(function (state) {
          return state.sigla === event.target.value;
        });
        this.cities_state = this.cities_state[0];
        this.filtrar();
      }else{
        this.filtrar();
      }
    },
  },
};
</script>
