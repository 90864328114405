<template>
  <div>
    <div class="login">
      <div class="container">
        <div class="row">
          <div
            class="col-12 align-items-center justify-content-center d-flex"
            style="flex-direction: column"
          >
            <div class="bloco-input-login">
              <the-mensagem v-if="message" :message="message" :type="type"></the-mensagem>
            </div>
            <img :src="this.origin + '/img/front/logo.png'" alt="Logo Fabet" />
            <form
              @submit.prevent="validaForm()"
              method="POST"
              :action="endpoint"
              id="enviaSenha"
            >
              <h1>Redefinir a senha:</h1>
              <div class="bloco-input-login" :class="erros.senha">
                <i
                  class="fa fa-eye-slash"
                  aria-hidden="true"
                  @click="mostraSenha()"
                  id="olho-fechado"
                ></i>
                <i
                  class="fa fa-eye"
                  aria-hidden="true"
                  @click="mostraSenha()"
                  id="olho"
                ></i>
                <label>Senha</label>
                <input
                  id="senha"
                  type="password"
                  aria-label="Senha"
                  v-model="form.senha"
                  required
                  placeholder=" "
                  name="password"
                />
              </div>
              <div class="bloco-input-login" :class="erros.confirmaSenha">
                <i
                  class="fa fa-eye-slash"
                  aria-hidden="true"
                  @click="confirmaSenha()"
                  id="olho-fechado2"
                ></i>
                <i
                  class="fa fa-eye"
                  aria-hidden="true"
                  @click="confirmaSenha()"
                  id="olho2"
                ></i>
                <label>Confirmar Senha</label>
                <input
                  id="senha2"
                  type="password"
                  aria-label="Senha"
                  v-model="form.confirmaSenha"
                  required
                  placeholder=" "
                  name="confirmacao_password"
                />
              </div>
              <input type="hidden" name="_codigo_recuperacao" :value="codigo" />
              <input type="hidden" name="_token" :value="token" />
              <input type="hidden" name="_method" value="POST" />
              <div class="erros" v-if="erroFrase.length">
                <ul>
                  <li v-for="erro in erroFrase" :key="erro">
                    <i class="fa fa-times" aria-hidden="true"></i> {{ erro }}
                  </li>
                </ul>
              </div>
              <button
                type="button"
                :disabled="button.disabled"
                v-html="button.text"
                @click="validaForm()"
                class="login-button"
              ></button>
            </form>
            <a href="https://drive.google.com/file/d/1kVKlMpWO8MRcTuk2RgpF10mOqiHEHU9g/view?usp=sharing" target="_blank" class="politica">Política de privacidade</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["codigo", "message", "type", "token", "endpoint", "errors"],
  data() {
    return {
      form: {
        senha: "",
        confirmaSenha: "",
      },
      erros: {
        senha: "",
        confirmaSenha: "",
      },
      erroFrase: [],
      button: {
        disabled: false,
        text: "Redefinir",
      },
    };
  },
  created() {
    var errors = JSON.parse(this.errors);
    errors.forEach((e) => {
      this.erroFrase.push(e);
    });
  },
  methods: {
    mostraSenha() {
      var senha = document.getElementById("senha");
      var icon = document.getElementById("olho");
      var icon2 = document.getElementById("olho-fechado");
      if (senha.getAttribute("type") == "password") {
        senha.setAttribute("type", "text");
        icon.style.display = "flex";
        icon2.style.display = "none";
      } else {
        senha.setAttribute("type", "password");
        icon.style.display = "none";
        icon2.style.display = "flex";
      }
    },
    confirmaSenha() {
      var senha = document.getElementById("senha2");
      var icon = document.getElementById("olho2");
      var icon2 = document.getElementById("olho-fechado2");
      if (senha.getAttribute("type") == "password") {
        senha.setAttribute("type", "text");
        icon.style.display = "flex";
        icon2.style.display = "none";
      } else {
        senha.setAttribute("type", "password");
        icon.style.display = "none";
        icon2.style.display = "flex";
      }
    },
    validaForm() {
      this.erroFrase = [];
      this.button.text = '<i class="fa fa-spinner fa-pulse"></i>';
      this.button.disabled = true;
      if (!this.form.senha) {
        this.erros.senha = "bloco-input-erro";
        this.erroFrase.push("Insira uma senha");
      }
      if (!this.form.confirmaSenha) {
        this.erros.confirmaSenha = "bloco-input-erro";
        this.erroFrase.push("Confirme a sua senha");
      }
      if (this.form.senha && this.form.confirmaSenha) {
        document.getElementById("enviaSenha").submit();
        this.button.text= "Redefinir";
        this.button.disabled = false;
      }
    },
  },
};
</script>
