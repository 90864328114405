import { render, staticRenderFns } from "./DataTablePublication.vue?vue&type=template&id=3455a893"
import script from "./DataTablePublication.vue?vue&type=script&lang=js"
export * from "./DataTablePublication.vue?vue&type=script&lang=js"
import style0 from "./DataTablePublication.vue?vue&type=style&index=0&id=3455a893&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports