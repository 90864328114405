<template>
  <div>
    <div class="cadastro_cv">
      <div class="container">
        <h1 class="ui-titulo">Cadastrar nova vaga:</h1>
        <form>
          <div class="row">
            <div class="col-12 col-xl-12 mt-4">
              <div class="bloco-radio">
                <input id="disponível" type="checkbox" v-model="form.ativo" />
                <label for="disponível">Disponível</label>
              </div>
            </div>
            <div
              class="col-12 d-flex align-items-center subtitulo"
              style="flex-direction: row"
            >
              <div class="linha"></div>
              <p>Dados da vaga</p>
              <div class="linha"></div>
            </div>
            <div class="col-12 col-xl-12">
              <div class="bloco-input" :class="erros.nome">
                <label>Título da vaga*</label>
                <input
                  type="text"
                  name="titulo"
                  v-model="form.nome"
                  aria-label="Título"
                  placeholder=" "
                  required
                />
              </div>
            </div>
            <div class="col-12">
              <div class="bloco-input" :class="erros.descricao">
                <label>Descrição da vaga*</label>
                <textarea
                  type="text"
                  name="descricao"
                  v-model="form.descricao"
                  required
                  aria-label="Descrição"
                  placeholder=" "
                ></textarea>
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="bloco-input" :class="erros.periodo">
                <label>Período*</label>
                <img :src="this.origin + '/img/front/icons/seta-preta.png'" alt="Icon Seta" />
                <select
                  type="text"
                  name="periodo"
                  v-model="form.periodo"
                  required
                  aria-label="Periódo"
                  placeholder=" "
                >
                  <option value="I">Integral</option>
                  <option value="M">Meio Período</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="bloco-input" :class="erros.modelo">
                <label>Modalidade*</label>
                <img :src="this.origin + '/img/front/icons/seta-preta.png'" alt="Icon Seta" />
                <select
                  type="text"
                  name="modalidade"
                  v-model="form.modelo"
                  required
                  aria-label="modalidade"
                  placeholder=" "
                >
                  <option value="P">Presencial</option>
                  <option value="H">Híbrido</option>
                  <option value="R">Remoto</option>
                </select>
              </div>
            </div>
            <div
              class="col-12 d-flex align-items-center subtitulo"
              style="flex-direction: row"
            >
              <div class="linha"></div>
              <p>Requisitos</p>
              <div class="linha"></div>
            </div>
            <div
              class="col-12 align-items-center"
              v-for="(requisito, index) in form.requisitos"
              :key="index"
            >
             <h4 class="titulo-blocos-add">Novo requisito:</h4>
              <div class="bloco-input bloco-exp">
                <label>Título*</label>
                <input
                  type="text"
                  v-model="form.requisitos[index].titulo"
                  required
                  aria-label="Nome da empresa"
                  placeholder=" "
                />
              </div>
              <div class="bloco-input bloco-exp">
                <label>Descrição*</label>
                <textarea
                  type="text"
                  v-model="form.requisitos[index].descricao"
                  required
                  aria-label="Nome da empresa"
                  placeholder=" "
                ></textarea>
              </div>
              <i
                class="fa fa-times-circle remove-registro"
                aria-hidden="true"
                @click="removeRequisitos(index)"
              ></i>
            </div>
            <div
              class="col-12 align-items-center d-flex justify-content-center"
              style="flex-direction: column"
            >
              <p class="adicionar" @click="addRequisitos()">
                <img
                  :src="this.origin + '/img/front/icons/adicionar.png'"
                  alt="Icon Adicionar"
                />Adicionar registro
              </p>
              <p class="exemplos">
                <span>Exemplo: </span>Título: Idiomas, Descrição: Necessário
                inglês fluente e espanhol nível básico
              </p>
            </div>
            <div
              class="col-12 d-flex align-items-center subtitulo"
              style="flex-direction: row"
            >
              <div class="linha"></div>
              <p>Outras Informações</p>
              <div class="linha"></div>
            </div>
            <div
              class="col-12 bloco-form align-items-center"
              v-for="(outro, index) in form.outros"
              :key="index"
            >
            <h4 class="titulo-blocos-add">Nova informação:</h4>
              <div class="bloco-input bloco-exp">
                <label>Título*</label>
                <input
                  type="text"
                  v-model="form.outros[index].titulo"
                  required
                  aria-label="Nome da empresa"
                  placeholder=" "
                />
              </div>
              <div class="bloco-input bloco-exp">
                <label>Descrição*</label>
                <textarea
                  type="text"
                  v-model="form.outros[index].descricao"
                  required
                  aria-label="Nome da empresa"
                  placeholder=" "
                ></textarea>
              </div>
              <i
                class="fa fa-times-circle remove-registro"
                aria-hidden="true"
                @click="removeOutros(index)"
              ></i>
            </div>
            <div
              class="col-12 align-items-center d-flex justify-content-center"
              style="flex-direction: column"
            >
              <p class="adicionar" @click="addOutros()">
                <img
                  :src="this.origin + '/img/front/icons/adicionar.png'"
                  alt="Icon Adicionar"
                />Adicionar registro
              </p>
              <p class="exemplos">
                <span>Exemplo:</span> Título: Vale alimentação, Descrição:
                Incluso vale alimentação de R$400,00
              </p>
            </div>
          </div>
        </form>
        <div class="col-12">
          <div class="erros" v-if="erroFrase.length">
            <ul>
              <li v-for="erro in erroFrase" :key="erro">
                <i class="fa fa-times" aria-hidden="true"></i> {{ erro }}
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-5" v-if="message">
          <the-mensagem
            v-if="message"
            :message="message"
            :type="typeMessage"
            class="mt-4"
          ></the-mensagem>
        </div>
        <div
          class="col-12 align-items-center d-flex justify-content-center"
          style="flex-direction: column"
        >
          <button
            :disabled="button.disabled"
            v-html="button.text"
            @click="validaForm()"
          ></button>
        </div>
        <div
          class="container align-items-center d-flex justify-content-center"
          style="flex-direction: column"
        >
          <div class="criado-sucesso">
            <p>Seu currículo foi criado com sucesso!</p>
          </div>
          <div class="criado-falha">
            <p>Ocorreu um erro ao criar seu currículo</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["endpoint"],
  data() {
    return {
      origin: window.origin,
      erroFrase: [],
      message: "",
      typeMessage: "",
      form: {
        nome: "",
        descricao: "",
        periodo: "",
        modelo: "",
        ativo: 1,
        requisitos: [],
        outros: [],
      },
      erros: {
        nome: "",
        descricao: "",
        modelo: "",
        periodo: "",
        ativo: "",
      },
      button: {
        disabled: false,
        text: "Enviar",
      },
    };
  },
  methods: {
    addRequisitos() {
      this.form.requisitos.push({
        titulo: "",
        descricao: "",
      });
    },
    removeRequisitos(index) {
      this.form.requisitos.splice(index, 1);
    },
    addOutros() {
      this.form.outros.push({
        titulo: "",
        descricao: "",
      });
    },
    removeOutros(index) {
      this.form.outros.splice(index, 1);
    },

    validaForm() {
      this.erroFrase = [];
      this.erros = [];
      this.message = "";
      for (const key in this.erros) {
        if (Object.hasOwnProperty.call(this.erros, key)) {
          this.erros[key] = "";
        }
      }
      if (!this.form.nome) {
        this.erros.nome = "bloco-input-erro";
        this.erroFrase.push("O campo de nome é obrigatório");
      }
      if (!this.form.descricao) {
        this.erros.descricao = "bloco-input-erro";
        this.erroFrase.push("O campo de descrição é obrigatório");
      }
      if (!this.form.modelo) {
        this.erros.modelo = "bloco-input-erro";
        this.erroFrase.push("O campo de modelo é obrigatório");
      }
      if (!this.form.periodo) {
        this.erros.periodo = "bloco-input-erro";
        this.erroFrase.push("O campo de período é obrigatório");
      }

      if (
        this.form.nome &&
        this.form.descricao &&
        this.form.periodo &&
        this.form.modelo
      ) {
        this.submitForm();
      }
    },
    resetForm() {
      this.form.nome = "";
      this.form.descricao = "";
      this.form.periodo = "";
      this.form.modelo = "";
      this.form.ativo = 1;
      this.form.requisitos = [];
      this.form.outros = [];
      this.erroFrase = [];
    },
    presentErrorsFromBack(errors) {
      for (const key in errors) {
        this.erros[key] = "bloco-input-erro";
        this.erroFrase.push(errors[key][0]);
      }
    },
    submitForm() {
      (this.button = {
        disabled: true,
        text: '<i class="fa fa-spinner fa-pulse"></i>',
      }),
        axios
          .post(this.endpoint, this.form)
          .then((response) => {
            if (response.data.message) {
              this.message = response.data.message;
              this.typeMessage = response.data.typeMessage;
            }
            this.resetForm();
          })
          .catch((error) => {
            if (error?.response?.data) {
              this.presentErrorsFromBack(error.response.data);
            }
          })
          .finally(() => {
            this.button = {
              disabled: false,
              text: "Enviar",
            };
          });
    },
  },
};
</script>
