<template>
  <div>
    <div class="cadastro_cv">
      <div class="container">
        <div class="vagas-top">
          <div class="vagas_titulo">
            <h1 class="ui-titulo">Vagas</h1>
            <div class="vagas_titulo-linha"></div>
          </div>
          <div class="vagas_filtro" v-on:click="filtro = true">
            <img  :src="this.origin + '/img/front/icons/filtro.png'" alt="Filtro" />
            <div class="vagas_filtro-hover">Filtros</div>
          </div>
        </div>
        <div class="ui-pesquisa">
          <button>
            <img
               :src="this.origin + '/img/front/icons/search.png'"
              alt="Pesquisar"
              type="submit"
              @click="ativarfiltro()"
            />
          </button>
          <input
            placeholder="Pesquisar..."
            type="text"
            name="search"
            v-model="pesquisa"
            @keyup.enter="ativarfiltro()"
          />
        </div>
        <div class="row" v-if="this.vagasCopias.length != 0">
          <div
            class="col-12 col-xl-6"
            v-for="(vaga, index) in vagasCopias"
            :key="index"
          >
            <a :href="'/vagas/' + vaga.slug">
              <div class="bloco-vaga">
                <h4>{{ vaga.nome }}</h4>
                <h5>{{ vaga.nome_empresa }}</h5>
                <p v-if="vaga.descricao.length > 150">{{ vaga.descricao.substring(0, 150) + '...' }}</p>
                <p v-else>{{vaga.descricao}}</p>
                <div class="items-vaga">
                  <div class="item">
                    <img src="img/front/icons/maps.png" alt="Icon" />{{
                      vaga.cidade
                    }}
                  </div>
                  <div class="item" v-if="vaga.periodo == 'I'">
                    <img src="img/front/icons/work.png" alt="Icon" />Integral
                  </div>
                  <div class="item" v-if="vaga.periodo == 'M'">
                    <img
                      src="img/front/icons/work.png"
                      alt="Icon"
                    />Meio-período
                  </div>
                  <div class="item" v-if="vaga.modelo == 'P'">
                    <i class="fa fa-user-o" aria-hidden="true"></i>Presencial
                  </div>
                  <div class="item" v-if="vaga.modelo == 'H'">
                    <i class="fa fa-user-o" aria-hidden="true"></i>Híbrido
                  </div>
                  <div class="item" v-if="vaga.modelo == 'R'">
                    <i class="fa fa-user-o" aria-hidden="true"></i>Remoto
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="mensagem-nao-encontrado" v-if="this.vagasCopias.length === 0">
          Nenhuma vaga cadastrada
        </div>
        <div class="mensagem-nao-encontrado" v-if="this.mensagemErro != '' && this.vagasCopias.length != 0">
          {{ this.mensagemErro }}
        </div>
        <div class="filtros filtros-vagas" v-if="filtro">
          <div class="filtros-top">
            <h3>Filtros:</h3>
            <img
              :src="this.origin + '/img/front/icons/close-azul.png'"
              alt="Fechar"
              v-on:click="filtro = false"
            />
          </div>
          <div class="filtros-select">
            <p>Período:</p>
            <img  :src="this.origin + '/img/front/icons/seta.png'" alt="seta" />
            <select name="periodo" @change="ativarfiltro()" v-model="periodo">
              <option>Todos</option>
              <option value="I">Integral</option>
              <option value="M">Meio Período</option>
            </select>
          </div>
          <div class="filtros-select">
            <p>Modalidade:</p>
            <img :src="this.origin + '/img/front/icons/seta.png'" alt="seta" />
            <select name="modalidade" @change="ativarfiltro()" v-model="modelo">
              <option>Todos</option>
              <option value="P">Presencial</option>
              <option value="H">Híbrido</option>
              <option value="R">Remoto</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["vagas"],
  data() {
    return {
      origin: window.origin,
      pesquisa: "",
      filtro: false,
      vagasCopias: "",
      periodo: "Todos",
      modelo: "Todos",
      mensagemErro: "",
    };
  },
  created() {
    this.vagasCopias = JSON.parse(JSON.stringify(this.vagas));
  },
  methods: {
    ativarfiltro() {
      axios
        .get(
          `/filtro-vagas?periodo=${this.periodo}&modelo=${this.modelo}&pesquisa=${this.pesquisa}`
        )
        .then((response) => {
          if (response.data.message) {
            this.mensagemErro = response.data.message;
            this.vagasCopias = [];
          } else {
            this.mensagemErro = "";
            this.vagasCopias = response.data;
            console.log(response.data);
          }
        })
        .catch((e) => {
          var msg = "Houve um erro ao filtrar! Por favor, tente novamente.";
          this.$swal("Oops!", msg, "error");
          console.log(e);
        })
        .finally(() => {});
    },
  },
};
</script>
