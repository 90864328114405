<template>
  <div>
    <div class="cadastro_cv">
      <div class="container">
        <div class="vagas-top">
          <div class="vagas_titulo">
            <h1 class="ui-titulo">Minhas Vagas</h1>
            <div class="vagas_titulo-linha"></div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 col-xl-6"
            v-for="(vaga, index) in this.vagas"
            :key="index"
          >
            <a :href="'/editar-vagas/' + vaga.slug">
              <div class="bloco-vaga">
                <p :class="vaga.ativo == 1 ? 'disponivel' : 'indisponivel'">
                  {{ vaga.ativo == 1 ? "Disponível" : "Indisponível" }}
                </p>
                <h4>{{ vaga.nome }}</h4>
                <h5>{{ parceiro.nome_empresa }}</h5>
                <p v-if="vaga.descricao.length > 150">{{ vaga.descricao.substring(0, 150) + '...'  }}</p>
                 <p v-else>{{ vaga.descricao }}</p>
                <div class="items-vaga">
                  <div class="item">
                    <img src="img/front/icons/maps.png" alt="Icon" />{{
                      parceiro.cidade
                    }}
                  </div>
                  <div class="item" v-if="vaga.periodo == 'I'">
                    <img src="img/front/icons/work.png" alt="Icon" />Integral
                  </div>
                  <div class="item" v-if="vaga.periodo == 'M'">
                    <img
                      src="img/front/icons/work.png"
                      alt="Icon"
                    />Meio-período
                  </div>
                  <div class="item" v-if="vaga.modelo == 'P'">
                    <i class="fa fa-user-o" aria-hidden="true"></i>Presencial
                  </div>
                  <div class="item" v-if="vaga.modelo == 'H'">
                    <i class="fa fa-user-o" aria-hidden="true"></i>Híbrido
                  </div>
                  <div class="item" v-if="vaga.modelo == 'R'">
                    <i class="fa fa-user-o" aria-hidden="true"></i>Remoto
                  </div>
                </div>
                <button class="button-minhas-vagas">Editar vaga</button>
              </div>
            </a>
          </div>
          <div v-if="vagas.length < 1" class="mensagem-nao-encontrado">
            Nenhuma vaga cadastrada ainda
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["vagas", "parceiro"],
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
